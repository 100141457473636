/*--------------------------------------------------------------
20. About
----------------------------------------------------------------*/
.cs_about.cs_style_1 {
  .cs_about_img {
    position: relative;
    @media (min-width: 992px) {
      padding-right: 70px;
    }
  }
  .cs_about_text_list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      padding-left: 60px;
      &:not(:last-child) {
        margin-bottom: 50px;
      }
      > i {
        position: absolute;
        left: 0;
        top: 5px;
        @media (max-width: 991px) {
          top: 2px;
        }
      }
      > h3 {
        margin-bottom: 25px;
      }
    }
  }
  .cs_about_mini_img {
    position: absolute;
    top: -75px;
    right: 20%;
  }
  .cs_right_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cs_rotate_animation {
    animation: rotate360 15s linear infinite;
  }
  &.cs_type_1 {
    .cs_about_mini_img {
      right: initial;
      top: 44%;
      left: 35%;
    }
    br {
      @media (max-width: 1400px) {
        display: none;
      }
    }
  }
  &.cs_type_2 {
    padding-left: 102px;
    @media (max-width: 1700px) {
      padding-left: 50px;
    }
    @media (max-width: 1400px) {
      padding-left: 0px;
    }
    .cs_about_mini_img {
      top: 50%;
      right: initial;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .cs_about_img {
      padding-right: 0;
      position: relative;
    }
  }
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cs_about.cs_style_2 {
  .cs_about_img {
    padding-left: 80px;
    @media (max-width: 1700px) {
      padding-left: 0;
    }
  }
  .cs_section_heading.cs_style_1 {
    p {
      max-width: 580px;
    }
  }
}
.cs_about.cs_style_3 {
  background: linear-gradient(119deg, #d2eaef 17.16%, #86bbf1 89.78%);
  .cs_section_heading.cs_style_1 {
    padding: 145px 80px;
    @media (max-width: 1400px) {
      padding: 145px 30px 145px 50px;
    }
    @media (max-width: 1199px) {
      padding: 50px 50px 50px 50px;
    }
    @media (max-width: 767px) {
      padding: 35px 24px 35px 24px;
    }
  }
  .cs_about_text {
    height: 100%;
    @media (max-width: 1199px) {
      height: initial;
    }
  }
  .cs_about_img {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .cs_section_minititle {
    max-width: 630px;
  }
  .cs_support_card {
    border-radius: 50px;
    border: 1px solid #fff;
    background-color: #fff;
    box-shadow: 6px 4px 70px 8px rgba(48, 123, 196, 0.09);
    padding: 20px 30px;
    display: flex;
    align-items: center;
    gap: 14px;
    position: absolute;
    right: -26%;
    bottom: 75px;
    .cs_support_card_icon {
      flex: none;
    }
    @media (max-width: 1700px) {
      right: -34%;
      bottom: 65px;
    }
    @media (max-width: 1199px) {
      position: initial;
      margin-left: 50px;
      margin-right: 50px;
      margin-bottom: 60px;
      @media (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 35px;
        padding: 20px 20px;
      }
    }
  }
}
