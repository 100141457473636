/*--------------------------------------------------------------
10. Isotope
----------------------------------------------------------------*/
.cs_isotop {
  transition: all 0.4s ease;
}

.cs_isotop.cs_has_gutter_24 {
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -24px;

  .cs_isotop_item {
    padding: 0 12px;
    margin-bottom: 24px;
  }
}
.cs_isotop.cs_has_gutter_24_67 {
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -67px;

  .cs_isotop_item {
    padding: 0 12px;
    margin-bottom: 67px;
  }
}
.cs_isotop.cs_has_gutter_80 {
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: -80px;

  .cs_isotop_item {
    padding: 0 40px;
    margin-bottom: 80px;
  }
}
.cs_isotop.cs_has_gutter_40 {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -30px;

  .cs_isotop_item {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

.cs_isotop.cs_has_gutter_100 {
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: -40px;

  .cs_isotop_item {
    padding: 0 50px;
    margin-bottom: 40px;
  }
}

.cs_isotop_col_6 .cs_grid_sizer,
.cs_isotop_col_6 .cs_isotop_item {
  width: 16.666667%;
}

.cs_isotop_col_5 .cs_grid_sizer,
.cs_isotop_col_5 .cs_isotop_item {
  width: 20%;
}

.cs_isotop_col_4 .cs_grid_sizer,
.cs_isotop_col_4 .cs_isotop_item {
  width: 25%;
}

.cs_isotop_col_3 .cs_grid_sizer,
.cs_isotop_col_3 .cs_isotop_item {
  width: 33.333333%;
}

.cs_isotop_col_2 .cs_grid_sizer,
.cs_isotop_col_2 .cs_isotop_item {
  width: 50%;
}

.cs_isotop_col_1 .cs_grid_sizer,
.cs_isotop_col_1 .cs_isotop_item {
  width: 100%;
}

.cs_grid_sizer {
  width: 33.333333%;
}

.cs_isotop_col_5 .cs_w20,
.cs_isotop_col_4 .cs_w20,
.cs_isotop_col_3 .cs_w20,
.cs_isotop_col_2 .cs_w20,
.cs_isotop_col_1 .cs_w20 {
  width: 20%;
}

.cs_isotop_col_5 .cs_w25,
.cs_isotop_col_4 .cs_w25,
.cs_isotop_col_3 .cs_w25,
.cs_isotop_col_2 .cs_w25,
.cs_isotop_col_1 .cs_w25 {
  width: 25%;
}

.cs_isotop_col_5 .cs_w33,
.cs_isotop_col_4 .cs_w33,
.cs_isotop_col_3 .cs_w33,
.cs_isotop_col_2 .cs_w33,
.cs_isotop_col_1 .cs_w33 {
  width: 33.333333%;
}

.cs_isotop_col_5 .cs_w50,
.cs_isotop_col_4 .cs_w50,
.cs_isotop_col_3 .cs_w50,
.cs_isotop_col_2 .cs_w50,
.cs_isotop_col_1 .cs_w50 {
  width: 50%;
}

.cs_isotop_col_5 .cs_w66,
.cs_isotop_col_4 .cs_w66,
.cs_isotop_col_3 .cs_w66,
.cs_isotop_col_2 .cs_w66,
.cs_isotop_col_1 .cs_w66 {
  width: 66.666666%;
}

.cs_isotop_col_5 .cs_w100,
.cs_isotop_col_4 .cs_w100,
.cs_isotop_col_3 .cs_w100,
.cs_isotop_col_2 .cs_w100,
.cs_isotop_col_1 .cs_w100 {
  width: 100%;
}

.cs_isotop_filter.cs_style1 {
  display: flex;
  align-items: center;
  gap: 20px 80px;
  @media (max-width: 1700px) {
    gap: 20px 30px;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 36px;
    @media (max-width: 1700px) {
      gap: 20px;
    }
    @media (max-width: 991px) {
      gap: 15px;
    }
    a,
    span {
      border: 1px solid $accent;
      border-radius: 20px;
      display: inline-block;
      cursor: pointer;
      color: $primary;
      padding: 11px 26px;
      transition: all 0.3s ease;
      @media (max-width: 991px) {
        padding: 7px 20px;
      }
      &:hover {
        background-color: rgba($accent, 0.15);
        color: $accent;
      }
    }
    .active {
      a,
      span {
        color: #fff;
        background-color: $accent;
      }
    }
  }
}
.cs_view_box {
  display: flex;
  align-items: center;
  gap: 30px;
}
.cs_view_box_in {
  display: flex;
  gap: 15px;
  button {
    padding: 0;
    border: none;
    background-color: transparent;
    transition: all 0.3s ease;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
      color: $accent;
    }
  }
}
.cs_doctors_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}
@media screen and (max-width: 1199px) {
  .cs_isotop.cs_has_gutter_100 .cs_isotop_item {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .cs_isotop.cs_has_gutter_100 {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -30px;
  }
  .cs_isotop.cs_has_gutter_80 {
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -25px;

    .cs_isotop_item {
      padding: 0 12px;
      margin-bottom: 25px;
    }
  }
  .cs_isotop_col_4 .cs_grid_sizer,
  .cs_isotop_col_4 .cs_isotop_item {
    width: 33.333333%;
  }
}

@media screen and (max-width: 991px) {
  .cs_isotop_col_4 .cs_grid_sizer,
  .cs_isotop_col_4 .cs_isotop_item {
    width: 50%;
  }

  .cs_isotop_col_4 .cs_w50 {
    width: 100%;
  }

  .cs_isotop_col_3 .cs_grid_sizer,
  .cs_isotop_col_3 .cs_isotop_item {
    width: 50%;
  }

  .cs_isotop_col_2 .cs_grid_sizer,
  .cs_isotop_col_2 .cs_isotop_item {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .cs_isotop_col_5 .cs_w50,
  .cs_isotop_col_4 .cs_w50,
  .cs_isotop_col_3 .cs_w50,
  .cs_isotop_col_2 .cs_w50,
  .cs_isotop_col_1 .cs_w50,
  .cs_isotop_col_5 .cs_w66,
  .cs_isotop_col_4 .cs_w66,
  .cs_isotop_col_3 .cs_w66,
  .cs_isotop_col_2 .cs_w66,
  .cs_isotop_col_1 .cs_w66 {
    width: 100%;
  }

  .cs_isotop_col_5 .cs_grid_sizer,
  .cs_isotop_col_5 .cs_isotop_item {
    width: 100%;
  }

  .cs_isotop_col_4 .cs_grid_sizer,
  .cs_isotop_col_4 .cs_isotop_item {
    width: 100%;
  }

  .cs_isotop_col_3 .cs_grid_sizer,
  .cs_isotop_col_3 .cs_isotop_item {
    width: 100%;
  }

  .cs_isotop_col_2 .cs_grid_sizer,
  .cs_isotop_col_2 .cs_isotop_item {
    width: 100%;
  }
}
